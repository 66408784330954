import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";

const Body = styled.div`
border: solid 1px #efefef;
border-radius: 8px;
border-top: 8px solid #004987;
background: #FFF;
box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.10);

img {
    max-width: 100%;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-left: 1rem;
}

.desc {
    color: #616161);
    font-size: 12px;
    padding: 0.5rem 0 1rem 1rem;
    display: block;
    min-height: 90px;
}

p{
  padding: 0 !important;
}
`;

const Frame = styled.div`
  padding: 1rem;
`;

export const PartnerBox: React.FC<{
  props: object;
  hideIndicators: boolean | undefined;
  hasButtons: boolean | undefined;
  customHeight: number | undefined;
}> = ({ props, hideIndicators, hasButtons, customHeight }) => {
  const showIndicators = typeof hideIndicators == "undefined" ? true : false;
  const showButtons = typeof hasButtons == "undefined" ? true : false;
  const height = typeof customHeight == "undefined" ? 80 : customHeight;
  const [indicatorCount, setIndicatorCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [minHeight, setMinHeight] = useState(0);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
        setLoading(false)
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const countUniqueIndicators = (array, agency, uniqueIndicators = new Set()) => {
    array.forEach((v) => {
      if (typeof v.indicator_name !== "undefined" && v.agency === agency) {
        uniqueIndicators.add(v.indicator_name);
      }

      if (typeof v.children !== "undefined") {
        countUniqueIndicators(v.children, agency, uniqueIndicators);
      }
    });
    return uniqueIndicators.size;
  };

  const showFewWords = (sentence, limit) => {
    const words = sentence.split(" ").slice(0, limit);
    const result = words.join(" ");
    return `${result}...`;
  };

  useEffect(() => {
    switch (props.value) {
      case "WHO":
      case "ILO":
        setIndicatorCount(countUniqueIndicators(topics, props.value));
        break;
      default:
        setIndicatorCount(0);
        break;
    }
  }, [props, topics]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    if (width < 1400 && width > 767) {
      setMinHeight(260);
    } else {
      setMinHeight(0);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <Body>
      <Frame>
        <div style={{ height: "153px", textAlign: "left", position: "relative" }}>
          {props.hidden ? (
            <img src={`./images/datacommons/${props.logo}`} />
          ) : (
            <Link to={`${routeDataCommonsConstants.PARTNER}/${props.value}`}>
              <img height={height} src={`./images/datacommons/${props.logo}`} />
            </Link>
          )}
        </div>

        {loading ? <ContentCard><Spinner/></ContentCard> : 
          <div style={{ minHeight: `${minHeight}px` }}>
          <div style={{ padding: "1rem", fontSize: "12px" }}>{showIndicators ? <> {indicatorCount} Indicators </> : <>&nbsp;</>}</div>
          <h3 style={{ paddingLeft: "1rem" }}>{props.label}</h3>
          <div className="desc" dangerouslySetInnerHTML={{ __html: showFewWords(props.desc, props.descLength) }}></div>

          {showButtons ? (
            <Row style={{ padding: "0 0 1rem 1rem" }} justify="start" gutter={8}>
              <Col>
                <Link to={`${routeDataCommonsConstants.PARTNER}/${props.value}`}>
                  <Button style={{ borderRadius: "4px" }}>Overview</Button>
                </Link>
              </Col>
              <Col>
                {showIndicators && (
                  <Link to={`${routeDataCommonsConstants.PARTNER}/${props.value}#3`}>
                    <Button style={{ borderRadius: "4px" }}>Indicators</Button>
                  </Link>
                )}
              </Col>
              <Col>{/*<Link to={`${routeDataCommonsConstants.PARTNER}/${props.value}#5`}><Button style={{borderRadius: '4px'}}>Updates</Button></Link>*/}</Col>
            </Row>
          ) : (
            <div style={{ height: "46px" }}></div>
          )}
        </div>}
      </Frame>
    </Body>
  );
};
