import { CaretDownOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { Spinner } from "../countries/CountriesContent";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { PartnerBox } from "./PartnerBox";

const PartnersTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 1rem 0;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 60%;
  }
  @media (min-width: 992px) {
    padding: 0 2rem 1rem;
  }
  @media only screen and (max-width: 800px) {
    p {
      width: 100%;
    }
  }
`;

const PartnersBody = styled.div`
.ant-select{
  width: 200px;
}
@media only screen and (max-width: 800px) {
.ant-select {
    width: 100%;
  }
}


p {
    color: #616161);
    font-size: 12px;
    padding: 0.5rem 0 1rem 1rem;
}
`;

const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
    height: 38px;
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    color: black;
    padding-top: 3px !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: -2px;
    font-size: 1rem;
  }
`;

const Partners: React.FC = () => {
  const [partnerList, setPartnerList] = useState(null);
  const [loading, setLoading] = useState<boolean>(true)
  const history = useHistory();

  const goToPartner = (value: { value: string }) => {
    history.push(`${routeDataCommonsConstants.PARTNER}/${value}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/partners.json`);
        const json = await response.json();
        setPartnerList(json);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <AppLayoutContent>
        <PartnersTop>
          <Layout className="container" style={{ backgroundColor: "transparent", padding: "0 1rem 1rem" }}>
            <AppBreadcrumb>Data Partners</AppBreadcrumb>
            <h3>Data Partners</h3>
            <p>
              A collaborative network of UN agencies contributing authoritative datasets to foster a deeper understanding of global issues. An increasing number
              of datasets will be routinely integrated as more data partners from the UN system join the UN Data platform.
            </p>
          </Layout>
        </PartnersTop>
        <Layout style={{ padding: "2rem 1rem" }} className="container">
          {loading ? <PartnersBody><Spinner/></PartnersBody> :
          <PartnersBody>
            <CountrySelectContainer>
              {partnerList && (
                <Select
                  suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                  onChange={goToPartner}
                  placeholder="Partners | All"
                  options={partnerList.filter((e) => !e.hidden)}
                />
              )}
            </CountrySelectContainer>
            <Row style={{ paddingTop: "2rem" }} gutter={[32, 48]}>
              {partnerList &&
                partnerList.map((item) => (
                  <Col sm={24} md={12} lg={8}>
                    <PartnerBox props={item} hasButtons={item?.hasButtons} hideIndicators={item?.hideIndicators} customHeight={item?.height} />
                  </Col>
                ))}
            </Row>
          </PartnersBody>
}
        </Layout>
      </AppLayoutContent>
    </>
  );
};

export default Partners;
