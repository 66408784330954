/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { red } from "@ant-design/colors";
import { Layout } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { dataCommonsClient } from "../../state";
import { formatUserMessage } from "../../utils";
import { QUERY_PARAM_QUERY, SAMPLE_NL_QUERY } from "../../utils/constants";
import { FulfillResponse } from "../../utils/types";
import CountriesContent from "../countries/CountriesContent";
import AppLayoutContent from "../shared/AppLayoutContent";
import { SearchBar } from "../shared/components";
// @ts-ignore
import { routeDataCommonsConstants, routePathConstants } from "../../../../helper/Common/RoutePathConstants";
import AppBreadcrumb from "../shared/AppBreadcrumb";

const QUERIES = {
  general: [
    {
      goal: 2,
      query: "Hunger in Nigeria",
      icon: "poverty.svg",
    },
    {
      goal: 5,
      query: "Gender equality across the world",
      icon: "gender3.svg",
    },
    {
      goal: 1,
      query: "Poverty in Mexico",
      icon: "employment.svg",
    },
    {
      goal: 3,
      query: "Progress on health goals in Pakistan",
      icon: "health.svg",
    },
  ],
  specific: [
    {
      goal: 5,
      query: "Women in managerial positions in India",
      icon: "employment.svg",
    },
    {
      goal: 7,
      query: "Access to primary school education in Afghanistan",
      icon: "school.svg",
    },
    {
      goal: 6,
      query: "How has access to electricity improved in Kenya?",
      icon: "electricity.svg",
    },
    {
      goal: 8,
      query: "How much food goes wasted around the world?",
      icon: "food.svg",
    },
    {
      goal: 4,
      query: "Access to clean drinking water in Mexico",
      icon: "water.svg",
    },
    {
      goal: 14,
      query: "How is the world combating unregulated fishing?",
      icon: "fishing.svg",
    },
    {
      goal: 14,
      query: "How has informal employment changed over time in Bangladesh?",
      icon: "employment.svg",
    },
  ],
  comparison: [
    {
      goal: 1,
      query: "Compare progress on poverty in Mexico, Nigeria and Pakistan",
      icon: "employment.svg",
    },
    {
      goal: 13,
      query: "Compare SDG goals on climate change between Brazil, China and India",
      icon: "climate change.svg",
    },
    {
      goal: 16,
      query: "Violence vs poverty across the world",
      icon: "world.svg",
    },
  ],
};

const SearchContainer = styled.div`
  color: #414042;
  margin: 0 auto;
  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: auto;
    margin-bottom: 2rem;
    text-align: center;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    border-bottom: 1px solid #999;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const SearchTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 1rem 1rem 4rem;
  .search-breadcrumb {
    padding: 0px;
  }
  @media (min-width: 992px) {
    padding: 0 1.5rem 4rem;

    .search-breadcrumb {
      padding: 0px 1rem;
    }
  }
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 3rem;
    color: inherit;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
`;

const StyledSubheader = styled.div`
  display: block;
  margin: 0 auto 2.5rem auto;
  max-width: 720px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);

  h4 {
    border-radius: 8px 8px 0 0;
  }

  h4 span {
    display: block;
    color: white;
    padding: 1rem;
  }
`;

const ErrorMessage = styled.div`
  margin: auto;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 450px;
  font-weight: 500;
  font-size: 1rem;
  color: ${red[6]};
`;

const SearchLinks = styled.div`
  margin: 5rem auto;
  padding: 0 1rem;
  @media (min-width: 992px) {
    padding: 0 1.5rem;
  }
`;

const StyledLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
`;

// const IconSquare = styled.div<{ color: string }>`
//   background: ${(p) => p.color};
//   height: 14px;
//   flex-shrink: 0;
//   margin: 4px 14px 4px 0;
//   width: 14px;
// `;
const StyledLink = styled(Link)`
  break-inside: avoid;
  color: inherit;
  display: block;
  font-size: 0.95rem;
  line-height: 22px;
  padding-left: 1rem;

  &:hover {
    color: #141414;
    text-decoration: underline;
  }
`;

const LinkGroup = styled.div`
  padding: 1rem;
  margin: 0 auto 1rem auto;
  max-width: 720px;
  @media (min-width: 992px) {
    padding: 1.5rem;
    margin: 0 auto 1.5rem auto;
  }
`;

const SearchBarContainer = styled.div`
  margin: auto;
  text-align: center;
  max-width: 525px;
`;

const AreaTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 1rem 0;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 60%;
  }
  @media (min-width: 992px) {
    padding: 1rem 1.5rem;
  }
  @media only screen and (max-width: 800px) {
    p {
      width: 100%;
    }
  }
`;

const SearchBarContainer2 = styled.div`
  .search {
    height: 100%;
    input {
      border-radius: 120px !important;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 40px;
    }
  }

  .anticon.anticon-search {
    margin-right: 5px;
    background-image: url(./images/datacommons/search-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 100%;
    width: 45px;
    right: 0;
    top: 0;

    svg {
      display: none;
    }
  }
`;

const SearchLayout = styled(Layout)`
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  background: rgb(240, 242, 245) !important;
  .ant-layout-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .-dc-goal-overview {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const Search = () => {
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [variableDcids, setVariableDcids] = useState<string[]>([]);
  const [placeDcids, setPlaceDcids] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [fulfillResponse, setFulfillResponse] = useState<FulfillResponse>();
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const searchParamQuery = searchParams.get(QUERY_PARAM_QUERY);


  const search = useCallback(
    async (searchQuery?: string) => {
      setIsSearching(true);
      setErrorMessage("");
      setUserMessage("");
      setVariableDcids([]);
      setPlaceDcids([]);
      setFulfillResponse(undefined);
      const context = fulfillResponse ? fulfillResponse.context : undefined;

      try {
        const fulfillResponse = await dataCommonsClient.detectAndFulfill(searchQuery || query, context);
        setFulfillResponse(fulfillResponse);
        setIsSearching(false);

        if (typeof fulfillResponse.userMessages[0] !== "undefined") {
          const formattedUserMessage = formatUserMessage(fulfillResponse.userMessages[0]);
          setUserMessage(formattedUserMessage);
        }

        if (fulfillResponse.failure) {
          setErrorMessage(fulfillResponse.failure || fulfillResponse.userMessages[0]);
          return;
        }
        if (fulfillResponse.place.dcid === "") {
          setErrorMessage(`Your search for "${searchQuery || query}" did not match any documents.`);
          return;
        }

        const variableDcids = fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
        const placesDcids = fulfillResponse?.places?.map((e) => e.dcid) || [];
        
        setVariableDcids(variableDcids);
        setPlaceDcids(placesDcids);
        if (variableDcids.length === 0 || placesDcids.length === 0) {
          setErrorMessage("Sorry, we couldn't find any data related to the place or topic you searched for. Please try another query.");
        }
      } catch (e) {
        setIsSearching(false);
        setErrorMessage(`Your search for "${searchQuery || query}" returned an error.`);
      }
    },
    [fulfillResponse, query, searchParamQuery]
  );

  useEffect(() => {
    // document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
    if (searchParamQuery) {
      setQuery(searchParamQuery || "");
      search(searchParamQuery);
    } else {
      setQuery("");
      setErrorMessage("");
      setVariableDcids([]);
      setPlaceDcids([]);
    }
  }, [searchParamQuery]);
  
  if (!searchParamQuery) {
    return (
      <>
        <AppLayoutContent id="top" style={{ overflow: "auto" }}>
          <SearchContainer>
            <SearchTop>
              <Layout className="container search-breadcrumb" style={{ backgroundColor: "transparent" }}>
                <AppBreadcrumb>Search</AppBreadcrumb>
              </Layout>
              <h3>Explore UN Data</h3>

              <SearchBarContainer>
                <SearchBar
                  isSearching={isSearching}
                  onSearch={(q) => {
                    const searchParams = new URLSearchParams();
                    searchParams.set(QUERY_PARAM_QUERY, q);
                    history.push(`${routePathConstants.DATA_COMMONS}search?${searchParams.toString()}`);
                  }}
                  placeholder={SAMPLE_NL_QUERY}
                />
              </SearchBarContainer>
              {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
              <p>
                Search and explore high-quality datasets and digital public goods right at your fingertips
                <br /> by delving into UN system data and insights - where your questions lead the way!
              </p>
            </SearchTop>

            <SearchLinks>
              <StyledSubheader>
                <h4 style={{ backgroundColor: "#283460" }}>
                  <span>Sample queries</span>
                </h4>

                <LinkGroup>
                  {QUERIES.general.map((q, i) => (
                    <StyledLinkContainer key={i}>
                      <img width={28} src={`./images/datacommons/${q.icon}`} />
                      <StyledLink className={`-dc-search-example`} to={`${location.pathname}?q=${q.query}`}>
                        {q.query}
                      </StyledLink>
                    </StyledLinkContainer>
                  ))}
                </LinkGroup>
              </StyledSubheader>
              <StyledSubheader>
                <h4 style={{ backgroundColor: "#932B44" }}>
                  <span>Try diving deeper</span>
                </h4>

                <LinkGroup>
                  {QUERIES.specific.map((q, i) => (
                    <StyledLinkContainer key={i}>
                      <img width={28} src={`./images/datacommons/${q.icon}`} />
                      <StyledLink className={`-dc-search-example`} to={`${location.pathname}?q=${q.query}`}>
                        {q.query}
                      </StyledLink>
                    </StyledLinkContainer>
                  ))}
                </LinkGroup>
              </StyledSubheader>
              <StyledSubheader>
                <h4 style={{ backgroundColor: "#A06020" }}>
                  <span>Combine and compare data</span>
                </h4>

                <LinkGroup>
                  {QUERIES.comparison.map((q, i) => (
                    <StyledLinkContainer key={i}>
                      <img width={28} src={`./images/datacommons/${q.icon}`} />
                      <StyledLink className={`-dc-search-example`} to={`${location.pathname}?q=${q.query}`}>
                        {q.query}
                      </StyledLink>
                    </StyledLinkContainer>
                  ))}
                </LinkGroup>
              </StyledSubheader>
            </SearchLinks>
          </SearchContainer>
        </AppLayoutContent>
      </>
    );
  }

  return (
    <>
      <AppLayoutContent id="top" style={{ display: "flex", flexDirection: "column", backgroundColor: "rgb(240, 242, 245)" }}>
        <AreaTop>
          <Layout className="container" style={{ backgroundColor: "transparent", padding: "0 1rem 1rem" }}>
            <span style={{ color: "#7C7C7C", fontSize: "14px", display: "block", padding: "0 0 20px" }}>
              <Link to={`${routeDataCommonsConstants.HOME_PATH}`}>Home</Link> &gt; Search
            </span>
            <SearchBarContainer2>
              <SearchBar
                placeholder="Ask your question"
                initialQuery={query}
                isSearching={false}
                onSearch={(q) => {
                  const searchParams = new URLSearchParams();
                  searchParams.set(QUERY_PARAM_QUERY, q);
                  history.push(`${routePathConstants.DATA_COMMONS}search?${searchParams.toString()}`);
                }}
              />
            </SearchBarContainer2>
          </Layout>
        </AreaTop>

        <SearchLayout className="container">
          {/* <Layout style={{ overflow: "auto" }}> */}
          <CountriesContent
            errorMessage={errorMessage}
            showNLSearch={false}
            hidePlaceSearch={true}
            query={query}
            fulfillResponse={fulfillResponse}
            isFetchingFulfillment={isSearching}
            onSearch={(q) => {
              const searchParams = new URLSearchParams();
              searchParams.set(QUERY_PARAM_QUERY, q);
              history.push(`${routePathConstants.DATA_COMMONS}search?${searchParams.toString()}`);
            }}
            userMessage={userMessage}
            variableDcids={variableDcids}
            placeDcids={placeDcids}
            setPlaceDcid={(placeDcid: string) => {
              setPlaceDcids([placeDcid]);
            }}
          />
          {/* </Layout> */}
        </SearchLayout>
      </AppLayoutContent>
    </>
  );
};
export default Search;
