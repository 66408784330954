import { Col, Collapse, Input, Layout, Row, Select, Switch } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state";
import { EARTH_PLACE_DCID, QUERY_PARAM_PLACE, QUERY_PARAM_VARIABLE, ROOT_TOPIC } from "../../utils/constants";
import CountriesContent, { GridSwitcher, Spinner } from "../countries/CountriesContent";
import { IndexTable } from "../goals/ViewIndex";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import AppSidebar from "../shared/AppSidebar";
import { ContentCard, CountrySelect, ExploreBtn, Footnotes } from "../shared/components";
import Indicator from "./Indicator";

const { Option } = Select;
const { Search } = Input;
const { Panel } = Collapse;
//const topics = topicsData.sort((a, b) => a.label.localeCompare(b.label));

const AreaTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 0 2rem; 1rem
  border-bottom: solid 2px #d3d3d3;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 40%;
  }
  @media only screen and (max-width: 800px) {
    p {
      width: 100%;
    }
  }
`;

const AreaBody = styled.div`
  .subheader {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #343434;
    margin: 2rem 1rem 1rem 1rem;
    padding-bottom: 0.5rem;
    display: block;
    @media (min-width: 992px) {
      margin: 19px 1.5rem 17px 1.5rem;
      padding-bottom: 0;
    }
  }
`;

const AreaCard = styled.div`
  .area-row {
    padding: 0 16px;
    display: inline-block;
    img {
      margin: 1rem 1rem 1rem 0;
    }
  }
  margin: 1rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  img {
    margin: 1rem;
    border-radius: 8px;
    padding: 0.5rem;
    height: 120px;
    background-color: #f1f1f1;
  }
  span {
    display: block;
    width: 80%;
    padding-bottom: 2rem;
    color: #4d4d4d;
  }
  h3 {
    font-size: 36px;
    padding: 1rem 0 0.3rem 0;
  }
  @media (min-width: 992px) {
    img {
      margin: 2rem;
    }
    h3 {
      font-size: 36px;
      padding: 2rem 0 0.3rem 0;
    }
    > .area-row {
      display: flex;
    }
    margin: 1.5rem;
  }
`;

const CountrySelectCard = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  h3 {
    font-size: 2rem;
    padding-bottom: 6px;
    padding-top: 6px;
    justify-content: space-between;
    font-weight: normal;
  }
  @media (min-width: 992px) {
    padding: 1.5rem;
    margin: 1.5rem;
  }
`;

const SearchBarContainer = styled.div`
  .search {
    height: 100%;
    input {
      border-radius: 120px !important;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 30px;
    }
  }

  .anticon.anticon-search {
    margin-right: 5px;
    background-image: url(./images/datacommons/search-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 100%;
    width: 45px;
    right: 0;
    top: 0;

    svg {
      display: none;
    }
  }
`;

const SubAreaTitle = styled.div`
  background-color: #fff;
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 8px;
  border: 2px solid;
  background: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: 992px) {
    margin: 1.5rem;
    padding: 0.8rem;
  }
`;

const SubAreacard = styled.div`
  background-color: #fff;
  // margin: 1rem;
  // padding: 0.5rem;
  border-radius: 8px;
  .block-header {
    padding: 16px 16px 0;
  }
  @media (min-width: 992px) {
    // margin: 1.5rem;
    padding: 0.5rem;
  }
  .no-data-class {
    padding: 1rem;
  }

  datacommons-bar::part(container),
  datacommons-line::part(container),
  datacommons-map::part(container) {
    margin: 0px !important;
    width: 100%;
    display: inline-block;
  }
  datacommons-highlight::part(container) {
    margin: 0 !important;
    width: calc(100% + 0px);
    display: inline-block;
  }
`;

const IndicatorHeader = styled.div`
  margin: 1rem;
  background-color: #fff;
  border-radius: 8px;
  span {
    font-weight: bold;
    display: block;
    padding: 1rem;
  }
  img {
    padding: 1rem;
  }
  @media (min-width: 992px) {
    margin: 1.5rem;
  }
`;

export const GridTableWrapper = styled.div`
  margin: 0 1rem;
  > input.ant-input {
    border-radius: 20px !important;
    padding: 4px 16px;
  }
  .results-subheader {
    padding: 8px 0 16px 16px;
    font-size: 12px;
  }
`;

const SubthemeIndicator = styled(Indicator)`
  padding: 24px;
  margin: 100px;
`;

const SwitcherWrap = styled.div`
  text-align: right;
  margin-bottom: 2rem;
  @media (max-width: 992px) {
    padding: 0 16px;
    margin-bottom: 1rem;
  }
`;
const StyledCollapse = styled(Collapse)`
  .ant-collapse-arrow {
    transform: translateY(-25%);
  }
`;

const ThematicAreaView: React.FC = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { hash } = useLocation();
  const [indicator, setIndicator] = useState("");
  const [theme, setTheme] = useState("");
  const [subtheme, setSubTheme] = useState("");
  const [countryPage, setCountryPage] = useState(false);
  const [url, setURL] = useState(routeDataCommonsConstants.THEMATIC_AREAS);
  const [SDGMode, setSDGMode] = useState(false);
  const [noData, setNoData] = useState(false);
  const [showIndex, setShowIndex] = useState(false);
  const [visibleCount, setVisibleCount] = useState(10);
  const [indicators, setIndicators] = useState([]);
  const [indicatorTree, setIndicatorTree] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const store = useStoreState((s) => s);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState<boolean>(true)

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const placeDcid = searchParams.get(QUERY_PARAM_PLACE) || "africa";
  const variableDcid = searchParams.get(QUERY_PARAM_VARIABLE) || ROOT_TOPIC;

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
        setLoading(false)
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const area = topics.find((e) => e.id == params.dcid);

  const columns: ColumnsType<DataType> = [
    {
      title: "Indicator",
      dataIndex: "indicator_name",
      key: "name",
      render: (text, record) => {
        if (typeof record.key != "undefined") {
          return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${theme.id}#${record.key}`}>{text}</Link>;
        } else {
          const parent = findSubTheme(topics, record.id);
          return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${theme.id}#${parent.id}+${record.id}`}>{text}</Link>;
        }
      },
    },
    /*{
      title: "Agency",
      dataIndex: "agency",
    },*/
  ];

  const findItem = (array, code) => {
    let result;
    array.some((child) => (child.id == code && (result = child)) || (result = findItem(child.children || [], code)));
    return result;
  };

  const findSubTheme = (nodes, childKey) => {
    for (const node of nodes) {
      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            for (const grandchild of child.children) {
              if (grandchild.id === childKey) {
                return child;
              }
            }
          }
        }
      }
    }
    return null;
  };

  const flatIndicators = (array, results = [], parents = [], parent = null, topicId = null) => {
    const uniqueIndicators = new Set(results.map((indicator) => indicator.indicator_name));

    array.forEach((item) => {
      let parentChain = [...parents, parent];
      if (item.indicator_name && (!topicId || parentChain.includes(topicId) || topicId === "all")) {
        if (!uniqueIndicators.has(item.indicator_name)) {
          results.push(item);
          uniqueIndicators.add(item.indicator_name);
        }
      }
      if (item.children) {
        flatIndicators(item.children, results, parentChain, item.id, topicId);
      }
    });

    return results;
  };

  const changeIndexView = (checked: boolean) => {
    setShowIndex(checked);
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const getCountryLabel = (id) => {
    if (store.countries.byDcid[id]) {
      return store.countries.byDcid[id].name;
    }
    if (store.regions.byDcid[id]) {
      return store.regions.byDcid[id].name;
    }
  };

  useEffect(() => {
    if (topics.length > 0) {
      const path = window.location.pathname.split("/");
      setTheme(findItem(topics, path.slice(-1)[0]));

      if (window.location.pathname.includes("countries")) {
        setCountryPage(true);
        setURL(routeDataCommonsConstants.COUNTRY);
      }
      const sp = window.location.search;
      if (sp && new URLSearchParams(sp).has("v")) {
        setSDGMode(true);
        history.push(routeDataCommonsConstants.COUNTRY + window.location.search);
      } else {
        setSDGMode(false);
      }
      document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
    }
  }, [topics, window.location.pathname, window.location.search]);

  useEffect(() => {
    if (hash) {
      const path = hash.replace(/#/g, "").split("+");
      if (path[1]) {
        setIndicator(findItem(topics, path[1]));
        setSubTheme(findItem(topics, path[0]));
      } else if (path[0]) {
        setIndicator(null);
        setSubTheme(findItem(topics, path[0]));
      }
    } else {
      setIndicator(null);
      setSubTheme(null);
    }
    document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
  }, [window.location.hash, topics]);

  useEffect(() => {
    setSearchTerm("");
    sortedIndicators();
    sortedIndicatorTree();
  }, [subtheme, theme]);

  useEffect(() => {
    // Reset the collapse state when params.dcid changes
    setActiveKey([]);
  }, [params.dcid]);

  const containerRef = useRef(null);

  useEffect(() => {
    const hideExtraNoDataDivs = () => {
      const divs = document.querySelectorAll("span.no-data-class");
      divs.forEach((div, index) => {
        if (index > 0) {
          div.style.display = "none";
        } else {
          div.style.display = "block";
        }
      });
    };

    // Initial call to hide extra divs
    hideExtraNoDataDivs();

    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          hideExtraNoDataDivs();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  /**
   * Update selected variable URL parameter
   */
  const setVariableDcid = useCallback(
    (variableDcid: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(QUERY_PARAM_VARIABLE, variableDcid);
      history.push(location.pathname + "?" + searchParams.toString());
    },
    [location]
  );

  /**
   * Update selected place URL parameter
   */
  const setPlaceDcid = useCallback(
    (placeDcid: string) => {
      const hash = window.location.hash;
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(QUERY_PARAM_PLACE, placeDcid);

      if (hash) {
        history.push(location.pathname + "?" + searchParams.toString() + hash);
      } else {
        history.push(location.pathname + "?" + searchParams.toString());
      }
    },
    [location]
  );

  const getIndicators = () => {
    return (
      <>
        <IndicatorHeader>
          <Row>
            <Col flex="1 1">
              <span>{indicator.indicator_name}</span>
            </Col>
            <Col flex="0 1">
              <img src={`${area?.icon}`} alt={indicator.indicator_name} />
            </Col>
          </Row>
        </IndicatorHeader>
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }} ref={containerRef}>
          {store.countries.dcids.length &&
            store.regions.dcids.length &&
            indicator.indicator_codes.map((i, k) => {
              return (
                <div>
                  <Indicator placeDcid="Earth" store={store} name={indicator.indicator_name} code={i} limit={100} />
                </div>
              );
            })}
        </SubAreacard>
      </>
    );
  };

  const getCountryPageIndicators = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">
          {`${area?.label}`} · {`${subtheme?.label}`}
        </h3>
        <SubAreacard>
          {store.countries.dcids.length &&
            store.regions.dcids.length &&
            indicator.indicator_codes.map((i, k) => {
              return (
                <Indicator setter={noData} hideAlternates={true} placeDcid={placeDcid} store={store} name={indicator.indicator_name} code={i} limit={100} />
              );
            })}
        </SubAreacard>
      </>
    );
  };

  const getTopics = () => {
    return (
      <>
        <AreaCard>
          <Row className="area-row">
            <Col flex="0 1">
              <img src={`${area?.icon}`} alt={area?.label} />
            </Col>
            <Col flex="1 1">
              <h3>{area?.label}</h3>
              <span>{area?.description}</span>
            </Col>
          </Row>
        </AreaCard>

        {
          <SwitcherWrap>
            <GridSwitcher>
              <Switch checked={showIndex} onChange={changeIndexView} size="small" /> Data catalogue
            </GridSwitcher>
          </SwitcherWrap>
        }
        {area?.children.length &&
          area?.children.map((v) => {
            return (
              <>
                {!showIndex && v.children.length > 0 && (
                  <>
                    <Link to={`#${v.id}`}>
                      <SubAreaTitle style={{ borderColor: `${theme.color};`, color: `${theme.color}` }}>{v.label}</SubAreaTitle>
                    </Link>
                    <SubAreacard>
                      {store.countries.dcids.length && <Indicator placeDcid="Earth" store={store} name={v.label} code={v.key} isTopic={true} limit={1} />}
                    </SubAreacard>
                  </>
                )}

                {v.id == "646330495-topic" && (
                  <SubAreacard>
                    <h3 className="block-header">{v.label}</h3>
                    <p style={{ padding: "1rem" }}>No data found.</p>
                  </SubAreacard>
                )}
              </>
            );
          })}

        {showIndex && (
          <GridTableWrapper>
            <Input value={searchTerm} onChange={nestedSearch} placeholder="Search" />
            <div className="results-subheader">
              Found <strong>{indicators.length}</strong> indicator(s)
            </div>
            {searchTerm.length > 1 ? (
              <IndexTable columns={columns} dataSource={indicators} />
            ) : (
              <StyledCollapse ghost activeKey={activeKey} onChange={setActiveKey}>
                {indicatorTree.map((v, k) => {
                  return (
                    <Panel header={v.indicator_name} key={k}>
                      <IndexTable columns={columns} dataSource={v.children} />
                    </Panel>
                  );
                })}
              </StyledCollapse>
            )}
          </GridTableWrapper>
        )}
      </>
    );
  };

  const getAllTopics = () => {
    return (
      <>
        {topics
          .filter((v) => v.type != "placeholder")
          .map((v) => {
            return (
              <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                <AreaCard>
                  <Row className="area-row">
                    <Col flex="0 1">
                      <img src={`${v?.icon}`} alt={v?.label} />
                    </Col>
                    <Col flex="1 1">
                      <h3>{v?.label}</h3>
                      <span>{v?.description}</span>
                    </Col>
                  </Row>
                </AreaCard>
              </Link>
            );
          })}
      </>
    );
  };

  const sortIndicators = (array) => {
    return array.filter((item) => typeof item.indicator_name !== "undefined").sort((a, b) => a.indicator_name.localeCompare(b.indicator_name));
  };

  const search = (value) => {
    const searchTerm = value.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm.length === 0) {
      sortedIndicators();
    } else {
      let filtered = indicators.filter((item) => item.indicator_name.toLowerCase().includes(searchTerm));
      filtered = Array.from(new Set(filtered.map((item) => item.indicator_name))).map((name) => filtered.find((item) => item.indicator_name === name));

      setIndicators(sortIndicators(filtered));
    }
  };

  const nestedSearch = (value: string) => {
    setSearchTerm(value.target.value);
    const flat = flatIndicators(topics, [], [], 0, theme.id);

    const filtered = flat?.filter((item) => {
      const indicator = item?.indicator_name?.toLowerCase() ?? "";
      return indicator.includes(value.target.value);
    });

    setIndicators(sortIndicators(filtered));
  };

  const sortedIndicators = () => {
    let indicators = [];

    if (subtheme) {
      indicators = flatIndicators(subtheme.children);
      indicators = Array.from(new Set(indicators.map((item) => item.indicator_name))).map((name) => indicators.find((item) => item.indicator_name === name));
      setIndicators(sortIndicators(indicators));
    }
  };

  const sortedIndicatorTree = () => {
    setIndicatorTree([]);
    let tmp = [];

    if (theme && theme.children && !subtheme) {
      theme.children.forEach((t) => {
        if (t.id != `${theme.id}-topic`) {
          tmp.push({ indicator_name: t.label, children: sortIndicators(t.children), key: t.id });
        }
      });

      setIndicators(flatIndicators(topics, [], [], 0, theme.id));
      setIndicatorTree(tmp);
    }
  };

  const getSubTopics = () => {
    return (
      <>
        <Row>
          <Col lg={12}>
            {showIndex && (
              <h3 className="subheader">
                {`${area?.label}`} / {`${subtheme?.label}`}
              </h3>
            )}
          </Col>
          <Col lg={12} style={{ textAlign: "right" }}>
            <GridSwitcher style={{ paddingTop: "17px", margin: "0 3.5rem" }}>
              <Switch checked={showIndex} onChange={changeIndexView} size="small" /> Data catalogue
            </GridSwitcher>
          </Col>
        </Row>
        {!showIndex && (
          <SubAreaTitle style={{ backgroundColor: `${theme.color}20`, borderColor: `${theme.color}`, marginBottom: 0, color: `${theme.color}` }}>
            {subtheme?.label}
          </SubAreaTitle>
        )}
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }}>
          {showIndex && (
            <GridTableWrapper>
              <Input value={searchTerm} onChange={search} placeholder="Search" />
              <div className="results-subheader">
                Found <strong>{indicators.length}</strong> indicator(s)
              </div>
              <IndexTable columns={columns} dataSource={indicators} />
            </GridTableWrapper>
          )}

          {!showIndex && (
            <div>
              {indicators.slice(0, visibleCount).map((v, index) => (
                <>
                  <Link to={`${hash + "+" + v.id}`}>
                    <SubAreaTitle style={{ margin: "1rem", borderColor: `${theme.color};`, color: `${theme.color}` }} key={index}>
                      {v.indicator_name}
                    </SubAreaTitle>
                  </Link>
                  <Indicator placeDcid="Earth" name={subtheme.label} store={store} code={v.indicator_codes[0]} isTopic={true} limit={1} />
                </>
              ))}
              {visibleCount < indicators.length && (
                <ExploreBtn>
                  <button onClick={loadMore}>Explore more</button>
                </ExploreBtn>
              )}
            </div>
          )}

          {/*!showIndex && <Indicator placeDcid="Earth" name={subtheme.label} store={store} code={subtheme.key} isTopic={true} limit={100} />*/}
        </SubAreacard>
      </>
    );
  };

  const getIndicatorsBySubTopic = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">
          {`${area?.label}`} · {`${subtheme?.label}`}
        </h3>
        <SubAreacard>
          {store.countries.dcids.length && store.regions.dcids.length && (
            <Indicator placeDcid={placeDcid} hideAlternates={true} store={store} name={subtheme.label} code={subtheme.key} isTopic={true} limit={100} />
          )}
        </SubAreacard>
      </>
    );
  };

  const getIndicatorsByTopic = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">{`${area?.label}`}</h3>
        <SubAreacard style={{ margin: "0 20px" }}>
          {store.countries.dcids.length && <Indicator store={store} placeDcid={placeDcid} name={area.label} code={area.key} isTopic={true} limit={1000} />}
        </SubAreacard>
      </>
    );
  };

  return (
    <>
      <AppLayoutContent>
        <div id="top"></div>
        {topics.length > 0 ? (
          <>
            {!countryPage ? (
              <Layout className="container">
                <AppBreadcrumb
                  items={[
                    { name: countryPage ? "Countries / Areas" : "Thematic Areas", to: url },
                    { name: subtheme && area?.label, to: `${url}/${theme.id}` },
                    { name: indicator && subtheme?.label, to: `${url}/${theme.id}` },
                  ].filter((i) => i.name)}
                >
                  {indicator ? indicator?.indicator_name : subtheme ? subtheme.label : area?.label}
                </AppBreadcrumb>
              </Layout>
            ) : (
              <Layout
                className="container"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <AppBreadcrumb>{countryPage ? "Countries / Areas" : "Thematic Areas"}</AppBreadcrumb>
              </Layout>
            )}
            <Layout style={{ height: "100%", flexGrow: 1, flexDirection: "row" }}>
              <AppSidebar
                dcid={params.dcid}
                placeDcid={placeDcid}
                setVariableDcid={setVariableDcid}
                showThematicAreas={true}
                thematicArea={area}
                onlySDGlink={countryPage ? false : true}
                countryPage={countryPage}
              />
              <Layout style={{ overflow: "auto", backgroundColor: "#f2f2f2" }}>
                <AreaBody>
                  {SDGMode ? (
                    <CountriesContent hidePlaceSearch={false} variableDcids={[variableDcid]} placeDcids={[EARTH_PLACE_DCID]} setPlaceDcid={() => {}} />
                  ) : (
                    <>
                      {countryPage ? (
                        <>
                          {indicator && getCountryPageIndicators()}
                          {subtheme && !indicator && getIndicatorsBySubTopic()}
                          {theme && !subtheme && !indicator && getIndicatorsByTopic()}
                        </>
                      ) : (
                        <>
                          {params.dcid == 0 && getAllTopics()}
                          {params.dcid != 0 && indicator && getIndicators()}
                          {params.dcid != 0 && subtheme && !indicator && getSubTopics()}
                          {params.dcid != 0 && theme && !subtheme && !indicator && getTopics()}
                        </>
                      )}
                    </>
                  )}
                  <div style={{ padding: "1rem" }}>
                    <Footnotes />
                  </div>
                </AreaBody>
              </Layout>
            </Layout>
          </>
        ) : (loading ? <ContentCard><Spinner/></ContentCard> : null)}
      </AppLayoutContent>
    </>
  );
};

export default ThematicAreaView;