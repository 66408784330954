import { CaretDownOutlined } from "@ant-design/icons";
import { Layout, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { Spinner } from "../countries/CountriesContent";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ContentCard } from "../shared/components";
import { DataInsights } from "./DataInsights";
import { Indicators } from "./Indicators";
import { Overview } from "./Overview";

const PartnersTop = styled.div`
  background-size: 100% auto;
  padding: 0 1rem 1rem;
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 40%;
  }
  @media only screen and (max-width: 992px) {
    padding: 0 0 1rem;
    p {
      width: 100%;
    }
  }
`;

const PartnersBody = styled.div`
  h3 {
    color: inherit;
    font-size: 42px;
    font-weight: 700;
  }

  .ant-select {
    width: 200px;
  }
  @media only screen and (max-width: 800px) {
    .ant-select {
      width: 100%;
    }
  }

  p {
    color: #333333;
    font-size: 16px;
    padding: 0.5rem 0 1rem 0;
    line-height: 22px;
    margin: 0;
  }
  .ant-tabs-tab {
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    border-bottom: 4px solid #5b92e5;
  }

  .contacts {
    list-style: none;
    font-size: 20px;
    color: #333333;
  }

  .short-desc {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
`;

const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
    height: 38px;
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    color: black;
    padding-top: 3px !important;
  }
  .ant-select-selection-item {
    padding-top: 3px !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: -2px;
    font-size: 1rem;
  }
`;

const PartnerView: React.FC = () => {
  const [partnerList, setPartnerList] = useState(null);
  const params = useParams();
  const partner = partnerList && partnerList.find((e) => e.value == params.slug);
  const history = useHistory();
  const { hash } = useLocation();
  const [loading, setLoading] = useState<boolean>(true)
  const [activeKey, setActiveKey] = useState("1");
  const [tabs, setTabs] = useState([
    { id: "1", label: "Overview", showOverview: true, showDataInsights: true },
    { id: "3", label: "Indicators" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/partners.json`);
        const json = await response.json();
        setPartnerList(json);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  const onKeyChange = (key) => setActiveKey(key);

  const goToPartner = (value: { value: string }) => {
    history.push(`${routeDataCommonsConstants.PARTNER}/${value}`);
  };

  const tabClick = (key: string) => {
    //document.getElementById(`block-${key}`).scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        setActiveKey(hash.replace(/#/g, ""));
      }, 200);
    }
    if (partner?.hideIndicators) {
      setTabs([{ id: "1", label: "Overview", showOverview: true, showDataInsights: true }]);
      setActiveKey("1");
    } else {
      setTabs([
        { id: "1", label: "Overview", showOverview: true, showDataInsights: true },
        { id: "3", label: "Indicators" },
      ]);
    }
  }, [partner]);

  return (
    <>
      <AppLayoutContent>
        <PartnersTop>
          <Layout className="container" style={{ backgroundColor: "transparent" }}>
            <AppBreadcrumb items={[{ name: "Data Partners", to: routeDataCommonsConstants.PARTNER }]}>{partner?.label}</AppBreadcrumb>
          </Layout>
        </PartnersTop>
        <Layout className="container">
          {loading ? <ContentCard><Spinner/></ContentCard>:
          <PartnersBody>
            <CountrySelectContainer>
              {partnerList && (
                <Select
                  onChange={goToPartner}
                  suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                  defaultValue={partner?.value}
                  placeholder="Partners | All"
                  options={partnerList.filter((e) => !e.hidden)}
                />
              )}
            </CountrySelectContainer>
            <Tabs style={{ paddingTop: "1rem" }} size="large" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
              {tabs.map((e) => (
                <Tabs.TabPane tab={e.label} key={e.id}>
                  {e.showOverview && partnerList && <Overview props={partner} />}
                  {e.showDataInsights && partnerList && !partner?.hideInsights && <DataInsights props={partner} />}

                  {!partner?.hideIndicators && partnerList && <Indicators props={{ partner: partner, indicatorView: activeKey == "3" ? true : false }} />}

                  {/*<Updates />*/}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </PartnersBody>
          }
        </Layout>
      </AppLayoutContent>
    </>
  );
};

export default PartnerView;
