/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ColorBar, HomeSection, SectionHeader } from "./components";
// @ts-ignore
import { Carousel, Col, Grid, Row } from "antd";
import { routeDataCommonsConstants } from "../../../../../src/helper/Common/RoutePathConstants";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
const useBreakpoint = Grid.useBreakpoint;

const Container = styled(HomeSection)`
  text-align: left;
  padding: 2rem 16px !important;
  @media (max-width: 768px) {
    padding: 16px !important;
  }
`;

const Header = styled(SectionHeader)`
  width: 100%;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  a {
    float: right;
    font-size: 14px;
    color: #283460;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-width: 67px;
    height: 100%;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 16px;
  }
`;

const CarouselSection = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  padding: 2rem;
  .ant-carousel .slick-slider .slick-dots-bottom {
    bottom: -60px;
  }
  .ant-carousel .slick-slider .slick-dots li.slick-active button {
    border: 3px solid #283460;
    background-color: #283460;
  }
  .ant-carousel .slick-slider .slick-dots li button {
    width: 12px;
    height: 12px;
    opacity: 1;
    border-radius: 50%;
    border: none;
    background-color: #d9d9d9;
  }
`;

const CarouselSectionMobile = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  padding: 2rem 0;
  .ant-carousel .slick-slider .slick-dots li.slick-active button {
    border: 3px solid #283460;
    background-color: #283460;
  }
  .ant-carousel .slick-slider .slick-dots {
    margin-bottom: 0;
    li button {
      width: 12px;
      height: 12px;
      opacity: 1;
      border-radius: 50%;
      border: none;
      background-color: #d9d9d9;
    }
  }
  @media (max-width: 768px) {
    padding: 16px 0 12px;
    .ant-carousel .slick-slider .slick-slide {
      .ant-row {
        margin-left: 0 !important;
        margin-righr: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .ant-col {
          margin-left: 0 !important;
          margin-righr: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
`;

const PhotoCard = styled.div`
  position: absolute;
  right: 16px;
  top: -20px;
  width: 37%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  clear: both;
  img {
    width: 100%;
    padding: 1rem;
  }
`;

const PhotoCardMobile = styled.div`
  position: absolute;
  right: 0;
  top: -145px;
  width: 54vw;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  clear: both;
  img {
    width: 100%;
    padding: 1rem;
  }
`;

const PhotoCard2 = styled.div`
  position: absolute;
  left: 16px;
  top: -20px;
  width: 37%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  clear: both;
  img {
    width: 100%;
    padding: 1rem;
  }
`;

const PhotoCardMobile2 = styled.div`
  position: absolute;
  left: 0;
  top: -145px;
  width: 54vw;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  clear: both;
  img {
    width: 100%;
    padding: 1rem;
  }
`;

const DescCard = styled.div`
  width: calc(37% - 75px);
  position: absolute;
  right: 18px;
  top: 285px;
  display: block;
  padding: 0;
  color: #7c7c7c;
  font-size: 18px;
  @media (max-width: 1200px) {
    top: 267px;
  }
  @media (max-width: 991px) {
    top: 185px;
    width: calc(37% - 60px);
  }
  a {
    display: flex;
    font-size: 14px;
    padding-top: 1rem;
    color: #283460;
    font-weight: bold;
  }
`;

const DescCardMobile = styled.div`
  display: block;
  padding: 0;
  color: #7c7c7c;
  font-size: 18px;
  a {
    display: flex;
    font-size: 16px;
    padding-top: 1rem;
    color: #283460;
    font-weight: 500;
  }
`;

const DescCard2 = styled.div`
  width: calc(37% - 65px);
  position: absolute;
  left: 40px;
  top: 285px;
  display: block;
  padding: 0;
  color: #7c7c7c;
  font-size: 18px;
  a {
    display: flex;
    font-size: 14px;
    padding-top: 1rem;
    font-weight: 500;
  }
  @media (max-width: 1200px) {
    top: 267px;
  }
  @media (max-width: 991px) {
    top: 185px;
  }
`;

const DescCardMobile2 = styled.div`
  display: block;
  padding: 0;
  clear: both;
  color: #7c7c7c;
  font-size: 20px;
  p {
    line-height: 26px !important;
  }
  a {
    display: flex;
    font-size: 16px;
    padding-top: 1rem;
    font-weight: 500;
    color: #283460;
  }
`;

const ImageCard = styled.div`
  .img-wrap {
    overflow: hidden;
  }
  width: 66%;
  img {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
  }

  small {
    padding-top: 10px;
    display: block;
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    width: 64vw;
    img {
      width: 64vw;
      height: 43vw !important;
    }
  }
`;

const ImageCard2 = styled.div`
  width: 66%;
  float: right;
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  small {
    text-align: right;
    padding-top: 10px;
    display: block;
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    float: right;
    width: 64vw;
    img {
      width: 64vw;
      height: 43vw !important;
    }
  }
  @media (min-width: 768px) {
    margin-right: -16px;
  }
`;

export const SDGSection = () => {
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/news.json`);
        const json = await response.json();
        setNews(json.filter((v) => v.category == "homepage-sdg" && v.featured == 1));
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ColorBar />
      <Container className="container">
        <Header>
          <Row>
            <Col style={{ textAlign: "left" }} xs={20}>
              Sustainable Development Goals (SDGs)
            </Col>
            <Col style={{ textAlign: "right" }} xs={4}>
              <Link to={`${routeDataCommonsConstants.SDG_HOME}`}>
                See all
                <span className="material-icons">chevron_right</span>
              </Link>
            </Col>
          </Row>
        </Header>
        {loading ? <ContentCard><Spinner/></ContentCard> : 
        <>
        {breakpoint.md ? (
          <CarouselSection>
            {news && news.length > 0 && (
              <Carousel autoplay={false} autoplaySpeed={15000}>
                {news.map((v) => {
                  return (
                    <Row gutter={[32, 32]}>
                      <Col xs={24}>
                        <div style={{ paddingTop: "40px", margin: "40px auto", maxWidth: "860px", position: "relative" }}>
                          <span>
                            {v.variant == 1 && (
                              <>
                                <PhotoCard style={{ borderTop: `8px solid ${v.color}` }}>
                                  <img src={`./images/datacommons/${v.card}`} />
                                </PhotoCard>
                                <DescCard>
                                  {v.desc}
                                  <Link to={v.link}>
                                    Read More
                                    <span className="material-icons">chevron_right</span>
                                  </Link>
                                </DescCard>
                                <ImageCard>
                                  <img width={500} src={`./images/datacommons/${v.img}`} />
                                  <small>© {v.credits}</small>
                                </ImageCard>
                              </>
                            )}

                            {v.variant == 2 && (
                              <>
                                <PhotoCard2 style={{ borderTop: `8px solid ${v.color}` }}>
                                  <img src={`./images/datacommons/${v.card}`} />
                                </PhotoCard2>
                                <DescCard2>
                                  {v.desc}
                                  <Link to={v.link}>
                                    Read More
                                    <span className="material-icons">chevron_right</span>
                                  </Link>
                                </DescCard2>
                                <ImageCard2>
                                  <img src={`./images/datacommons/${v.img}`} />
                                  <small>© {v.credits}</small>
                                </ImageCard2>
                              </>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Carousel>
            )}
          </CarouselSection>
        ) : (
          <CarouselSectionMobile>
            {news && news.length > 0 && (
              <Carousel autoplay autoplaySpeed={15000}>
                {news.map((v, i) => {
                  return (
                    <Row key={i} gutter={[32, 32]}>
                      <Col xs={24}>
                        <div style={{ width: "100%", margin: "145px auto 90px", position: "relative" }}>
                          <span style={{}}>
                            {v.variant == 1 && (
                              <>
                                <PhotoCardMobile style={{ borderTop: `8px solid ${v.color}` }}>
                                  <img src={`./images/datacommons/${v.card}`} />
                                </PhotoCardMobile>
                                <ImageCard>
                                  <img src={`./images/datacommons/${v.img}`} />
                                  <small>© {v.credits}</small>
                                </ImageCard>
                                <DescCardMobile>
                                  {v.desc}
                                  <Link to={v.link}>
                                    Read More
                                    <span className="material-icons">chevron_right</span>
                                  </Link>
                                </DescCardMobile>
                              </>
                            )}

                            {v.variant == 2 && (
                              <>
                                <PhotoCardMobile2 style={{ borderTop: `8px solid ${v.color}` }}>
                                  <img src={`./images/datacommons/${v.card}`} />
                                </PhotoCardMobile2>
                                <ImageCard2>
                                  <img src={`./images/datacommons/${v.img}`} />
                                  <small>© {v.credits}</small>
                                </ImageCard2>
                                <DescCardMobile2>
                                  <p dangerouslySetInnerHTML={{ __html: v.desc }} />
                                  <Link to={v.link}>
                                    Read More
                                    <span className="material-icons">chevron_right</span>
                                  </Link>
                                </DescCardMobile2>
                              </>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Carousel>
            )}
          </CarouselSectionMobile>
        )}
        </>
        }
      </Container>
      <ColorBar />
    </>
  );
};
