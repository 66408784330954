import { Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import { DataCatalogue } from "./DataCatalogue";

const Body = styled.div`
  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    @media (min-width: 992px) {
      font-size: 24px;
    }
  }

  .title-section {
    padding-bottom: 10px;
    border-bottom: solid 1px #dddae5;
    margin-bottom: 2rem;
    margin-top: 2rem;
    a {
      white-space: nowrap;
      font-weight: 500;
      line-height: 25px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .desc {
    color: #616161;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
  }
  h4 {
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
  }
  .ant-select {
    width: 50% !important;
  }

  @media only screen and (max-width: 800px) {
    .ant-select {
      width: 100% !important;
    }
  }
`;

const DatacommonsMapContainer = styled.div`
  datacommons-slider::part(container) {
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #e3e3e3;
    border-radius: 0;
  }
  datacommons-map::part(source-separator) {
    display: none;
  }
  datacommons-map::part(source-show-metadata-link) {
    display: none;
  }
`;

const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
    height: 38px;
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    color: black;
    padding-top: 3px !important;
  }
  .ant-select-selection-item {
    padding-top: 3px !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: -2px;
    font-size: 1rem;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    border-radius: 20px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
  parents: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "Indicator",
    dataIndex: "indicator",
    key: "name",
    render: (text, record) => {
      return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${record.parents[1]}#${record.parents[2]}+${record.id}`}>{text}</Link>;
    },
  },
];

export const Indicators: React.FC<{ props: object }> = ({ props }) => {
  const [indicators, setIndicators] = useState(null);
  const [value, setValue] = useState("all");
  const [treeData, setTreeData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
        setLoading(false)
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  const getIndicators2 = (array, results, parents, parent, topicId, agency) => {
    array.forEach((v, k) => {
      let p = [...parents, parent];
      if (typeof v.indicator_name !== "undefined" && v.agency == agency && (!topicId || p.includes(topicId) || topicId == "all")) {
        results.push({ id: v.id, indicator: v.indicator_name, parents: p });
      }

      if (typeof v.children !== "undefined") {
        results = getIndicators(v.children, results, p, v.id, topicId, agency);
      }
    });
    return results;
  };

  const getIndicators = (array, results, parents, parent, topicId, agency, seenNames = new Set()) => {
    array.forEach((v, k) => {
      let p = [...parents, parent];
      if (
        typeof v.indicator_name !== "undefined" &&
        v.agency == agency &&
        (!topicId || p.includes(topicId) || topicId == "all") &&
        !seenNames.has(v.indicator_name) // Check if the name has already been seen
      ) {
        seenNames.add(v.indicator_name); // Add the name to the seen set
        results.push({ id: v.id, indicator: v.indicator_name, parents: p });
      }

      if (typeof v.children !== "undefined") {
        results = getIndicators(v.children, results, p, v.id, topicId, agency, seenNames);
      }
    });
    return results;
  };

  const buildTree = (nodes, parentKey) => {
    const tree = [];
    if (parentKey == "") {
      tree.push({ key: "all", value: "all", label: "View All" });
    }

    nodes.forEach((node) => {
      if (node.children && node.children.length > 0) {
        const children = buildTree(node.children, node.id);
        const { icon, key, label, id } = node;
        const newNode = { icon, key: node.id, label, value: node.id };
        if (children.length) {
          newNode.children = children;
        }
        tree.push(newNode);
      } else if (!parentKey && node.id != 0) {
        tree.push(node); // Include top-level nodes
      }
    });
    return tree;
  };

  const sortIndicators = (array) => {
    return array.filter((item) => typeof item.indicator !== "undefined").sort((a, b) => a.indicator.localeCompare(b.indicator));
  };

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();

    if (keyword.length === 0) {
      // Reset to all indicators if the search input is cleared
      const allIndicators = getIndicators(topics, [], [], 0, value, props.partner.value);
      setIndicators(sortIndicators(allIndicators));
    } else {
      // Filter the indicators based on the search keyword
      const allIndicators = getIndicators(topics, [], [], 0, value, props.partner.value);
      const filtered = allIndicators.filter((item) => {
        const indicator = item.indicator.toLowerCase();
        return indicator.includes(keyword);
      });

      setIndicators(sortIndicators(filtered));
    }
  };

  useEffect(() => {
    setIndicators(sortIndicators(getIndicators(topics, [], [], 0, value, props.partner.value)));
    setTreeData(buildTree(topics, ""));
  }, [topics, props, value]);

  return (
    <Body id="block-3">
      <Row className="title-section">
        <Col xs={12}>
          <h3 className="title">Indicators</h3>
        </Col>
        <Col xs={12} style={{ textAlign: "right" }}>
          {!props.indicatorView && (
            <a href={`${routeDataCommonsConstants.PARTNER}/${props.partner.value}?v=${Date.now()}#3`}>
              See all
              <span className="material-icons">chevron_right</span>
            </a>
          )}
        </Col>
      </Row>
      {props.partner.value == "WHO" && (
        <Row gutter={24} style={{ marginBottom: "30px" }}>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                apiroot="https://unsdg.datacommons.org"
                subscribe="map-who/WSH_2"
                header="Water, Sanitation and Hygiene Attributable Deaths in Children Under 5 Years in the World (${date})*"
                variable="who/WSH_2"
                sources="https://apps.who.int/gho/data/node.imr.WSH_2?lang=en"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                placenameprop="unDataLabel"
                geojsonprop="geoJsonCoordinatesUN"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://unsdg.datacommons.org"
                    publish="map-who/WSH_2"
                    variable="who/WSH_2"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                apiroot="https://unsdg.datacommons.org"
                subscribe="map-who/GHED_CHEGDP_SHA2011"
                header="Current Health Expenditure as Percentage of Gross Domestic Product in the World (${date})*"
                variable="who/GHED_CHEGDP_SHA2011"
                sources="https://www.who.int/data/gho/indicator-metadata-registry/imr-details/4950"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                placenameprop="unDataLabel"
                geojsonprop="geoJsonCoordinatesUN"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://unsdg.datacommons.org"
                    publish="map-who/GHED_CHEGDP_SHA2011"
                    variable="who/GHED_CHEGDP_SHA2011"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                apiroot="https://unsdg.datacommons.org"
                subscribe="map-who/HEMOGLOBINLEVEL_CHILDREN_MEAN"
                header="Mean Hemoglobin Level of Children Aged 6-59 Months in the World (${date})*"
                variable="who/HEMOGLOBINLEVEL_CHILDREN_MEAN"
                sources="https://www.who.int/data/gho/indicator-metadata-registry/imr-details/5740"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                placenameprop="unDataLabel"
                geojsonprop="geoJsonCoordinatesUN"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://unsdg.datacommons.org"
                    publish="map-who/HEMOGLOBINLEVEL_CHILDREN_MEAN"
                    variable="who/HEMOGLOBINLEVEL_CHILDREN_MEAN"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
        </Row>
      )}

      {props.partner.value == "ILO" && (
        <Row gutter={24} style={{ marginBottom: "30px" }}>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                sources="https://data.ilo.org/vis?tm=ILR_CBCT_NOC_RT&amp;pg=0&amp;hc[dataflowId]=DF_ILR_CBCT_NOC_RT&amp;snb=1&amp;df[ds]=ds-ilo-prod&amp;df[id]=DF_ILR_CBCT_NOC_RT&amp;df[ag]=ILO&amp;df[vs]=1.0"
                subscribe="map-ilo/ILR_CBCT_RT"
                apiroot="https://staging.unsdg.datacommons.org"
                header="Collective Bargaining Coverage Rate in the World (${date})"
                variable="ilo/ILR_CBCT_RT"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://staging.unsdg.datacommons.org"
                    publish="map-ilo/ILR_CBCT_RT"
                    variable="ilo/ILR_CBCT_RT"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                sources="https://data.ilo.org/vis?tm=EAR_XTLP_SEX_RT&amp;pg=0&amp;hc[dataflowId]=DF_EAR_XTLP_SEX_RT&amp;snb=1&amp;df[ds]=ds-ilo-prod&amp;df[id]=DF_EAR_XTLP_SEX_RT&amp;df[ag]=ILO&amp;df[vs]=1.0"
                subscribe="map-ilo/EAR_XTLP_RT"
                apiroot="https://staging.unsdg.datacommons.org"
                header="Low Pay Rate in the World (${date}) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                variable="ilo/EAR_XTLP_RT"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://staging.unsdg.datacommons.org"
                    publish="map-ilo/EAR_XTLP_RT"
                    variable="ilo/EAR_XTLP_RT"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
          <Col lg={8}>
            <DatacommonsMapContainer>
              <datacommons-map
                subscribe="map-ilo/STR_DAYS_RT"
                apiroot="https://staging.unsdg.datacommons.org"
                header="Rates of Days Not Worked Due to Strikes and Lockouts in the World (${date})"
                variable="ilo/STR_DAYS_RT"
                parentplace="Earth"
                childplacetype="Country"
                showexploremore="false"
                date="HIGHEST_COVERAGE"
              >
                <div slot="footer">
                  <datacommons-slider
                    apiroot="https://staging.unsdg.datacommons.org"
                    publish="map-ilo/STR_DAYS_RT"
                    variable="ilo/STR_DAYS_RT"
                    parentplace="Earth"
                    childplacetype="Country"
                  ></datacommons-slider>
                </div>
              </datacommons-map>
            </DatacommonsMapContainer>
          </Col>
        </Row>
      )}

      {props.indicatorView && (
        <>
          {/*<CountrySelectContainer>
            <TreeSelect
              showSearch
              style={{ marginTop: "3rem" }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Thematic areas"
              suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
              treeDefaultExpandAll
              onChange={onChange}
              treeData={treeData}
              filterTreeNode={(search, item) => {
                return item.label?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
            />
          </CountrySelectContainer>*/}
          <StyledInput style={{ borderRadius: "20px" }} onChange={search} placeholder="Search" />

          <span style={{ display: "block", padding: "10px 0", fontSize: "12px" }}>
            {indicators && (
              <>
                Found <strong>{indicators.length}</strong> indicator(s)
              </>
            )}
          </span>
          {loading ? <ContentCard><Spinner/></ContentCard> :
          <DataCatalogue props={{ columns: columns, dataSource: indicators }} />
          }
        </>
      )}
    </Body>
  );
};
