/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Icon, { LoadingOutlined } from "@ant-design/icons";
import { Collapse, Grid, Menu, Spin, Tooltip } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { MenuItemType, useStoreActions, useStoreState } from "../../state";
import { ROOT_TOPIC } from "../../utils/constants";
import RespSidebar from "./RespSidebar";

const { Panel } = Collapse;
const useBreakpoint = Grid.useBreakpoint;

const HEADER_HEIGHT_XL = "140px";
const HEADER_HEIGHT_XS = "173px";

const MenuTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
`;

const StyledMenu = styled(Menu)`
  .ant-menu-submenu-title {
    &:hover {
      background: #e5e5e5;
      > .ant-menu-submenu-arrow {
        color: #000;
      }
    }
  }
  .ant-menu-submenu .ant-menu-item:hover {
    background-color: #e5e5e5;
    color: #000;
  }
  > li {
    div.ant-menu-submenu-title {
      padding-left: 20px !important;
    }
    // .ant-menu-submenu-open.ant-menu-submenu-inline {
    //   background:#4d4d4d;
    //   color:#fff;
    // }
  }
  .ant-menu-submenu .ant-menu-item-only-child.ant-menu-item {
    padding-left: 20px !important;
  }
  .ant-menu-submenu .ant-menu-sub > .ant-menu-submenu .ant-menu-sub > .ant-menu-item-only-child.ant-menu-item {
    padding-left: 28px !important;
  }
  .-title-content {
    flex: auto !important;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
  }

  /** 
   * Ensures tooltips are placed directly to the right of the menu instead of
   * overflowing in the middle of the page 
   */
  .-title-content span,
  .ant-menu-title-content span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 16px !important;
  }
`;

const SidebarContent = styled.div<{ dcid: number }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-menu-submenu-arrow {
    &:before {
      transform: rotate(-45deg) translateX(2.5px);
    }
    &:after {
      transform: rotate(45deg) translateX(-2.5px);
    }
  }
  *[aria-expanded="true"] {
    .ant-menu-submenu-arrow:before {
      transform: rotate(45deg) translateX(2.5px);
    }
    .ant-menu-submenu-arrow:after {
      transform: rotate(-45deg) translateX(-2.5px);
    }
  }
  .ant-collapse-content-box {
    padding: 0;
    ul {
      li {
        // .ant-menu-title-content{
        //   padding-left: 8px;
        // }
      }
    }
  }
  .ant-menu-item-selected span {
    color: #fff;
  }
  .ant-menu-inline .ant-menu-item:after {
    display: none;
  }
  .ant-collapse-icon-position-end > .ant-collapse-item {
    margin-top: 16px;

    > .ant-collapse-header {
      padding: 8px 40px 8px 20px;

      span.ant-collapse-header-text img {
        margin-right: 8px;
      }
    }
  }

  .ant-collapse-icon-position-end > .ant-collapse-item:nth-of-type(1) > .ant-collapse-header {
    ${(props) => {
      return (
        props.dcid == 0 &&
        `
          background-color: #e6f7ff;
          border-radius: 50px;
        `
      );
    }}
  }

  .ant-menu-inline .ant-menu-submenu-title {
    margin: 0 0;
  }
  .ant-menu-inline .ant-menu-item {
    margin: 0 0;
    height: 36px;
    line-height: 36px;
  }
  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box > .ant-menu-root.ant-menu-inline > li > div {
    padding-top: 4px;
    padding-bottom: 4px;
    height: 48px;
  }
  .goals-menu {
    .ant-menu-submenu-title {
      height: 48px;
    }
    .ant-menu-item {
      padding-left: 20px !important;
    }
    > .ant-menu-item {
      height: 48px;
      line-height: 36px;
      border-radius: 50px;
      width: calc(100% - 16px);
      margin: 0 6px 16px;
      font-size: 15px;
      font-weight: 600;
      padding-left: 12px !important;
      padding-right: 0;
      > img {
        margin-right: 0 !important;
      }
    }
  }
`;

const AppSidebar: React.FC<{
  placeDcid: string;
  variableDcid: string;
  setVariableDcid: (variableDcid: string) => void;
}> = (props) => {
  const { placeDcid, setVariableDcid, variableDcid, showThematicAreas, thematicArea, onlySDGlink, countryPage, dcid } = props;
  const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);
  const fetchPlaceSidebarMenuHierarchy = useStoreActions((a) => a.fetchPlaceSidebarMenuHierarchy);
  const allTopicDcids = useStoreState((s) => s.allTopicDcids);
  const [placeSidebarMenuHierarchy, setPlaceSidebarMenuHierarchy] = useState<MenuItemType[]>([]);
  const [topics, setTopics] = useState([]);
  const [defaultPanel, setDefaultPanel] = useState(countryPage ? 2 : 1);
  const [openSDGs, setOpenSDGs] = useState([variableDcid]);
  const [openTopic, setOpenTopic] = useState(thematicArea ? [thematicArea.id.toString()] : []);
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { hash } = useLocation();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true })));
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const SDGheader = () => (
    <span className="sdg-header" style={{ fontWeight: "700" }}>
      {onlySDGlink ? (
        <Link style={{ color: "#000" }} to={`${routeDataCommonsConstants.SDG_HOME}`}>
          <img style={{ width: "32px" }} src="./images/datacommons/sdg-wheel-transparent.svg?v=2.0" />
          Sustainable Development Goals
        </Link>
      ) : (
        <>
          <img style={{ width: "32px" }} src="./images/datacommons/sdg-wheel-transparent.svg?v=2.0" />
          Sustainable Development Goals
        </>
      )}
    </span>
  );

  const ThematicAreaHeader = () => (
    <span className="area-header" style={{ fontWeight: "700" }}>
      <img style={{ width: "32px" }} src="./images/datacommons/ta-theme.svg?v=2.0" />
      {onlySDGlink ? "All Thematic Areas" : "Thematic Areas"}
    </span>
  );

  const getMenuItem = (item: MenuItemType) => {
    const tagId = `${item.key.replace(/[\/\.]/g, "_")}`;
    if (item.children && item.children.length > 0) {
      return (
        <SubMenu
          className={`-dc-sidebar-submenu -dc-sidebar-submenu-${item.key}`}
          key={item.key}
          title={
            <Tooltip mouseEnterDelay={0.6} placement={breakpoint.xs ? "top" : "right"} title={item.label}>
              {item.label}
            </Tooltip>
          }
          icon={item.icon}
        >
          {item.children.map((subItem) => getMenuItem(subItem))}
        </SubMenu>
      );
    }

    //tuck all the content underneath -dc/topic/sdg if thematic areas are shown
    if (item.key != "dc/topic/sdg" && showThematicAreas) {
      return (
        <Menu.Item className={`-dc-sidebar-menu-item -dc-sidebar-menu-item-${item.key}`} key={item.key} icon={item.icon} id={tagId}>
          <Tooltip mouseEnterDelay={0.6} placement={breakpoint.xs ? "top" : "right"} title={item.label}>
            <span>{item.key.includes("summary-dc") ? item.label : `${item.key.split("_")[1]} ${item.label}`}</span>
          </Tooltip>
        </Menu.Item>
      );
    }

    if (!showThematicAreas) {
      return (
        <Menu.Item className={`-dc-sidebar-menu-item -dc-sidebar-menu-item-${item.key}`} key={item.key} icon={item.icon} id={tagId}>
          <Tooltip mouseEnterDelay={0.6} placement={breakpoint.xs ? "top" : "right"} title={item.label}>
            {item.key == "dc/topic/sdg" ? (
              <span style={{ color: "#000" }}>{item.label}</span>
            ) : (
              <span>{item.key.includes("summary-dc") ? item.label : `${item.key.split("_")[1]} ${item.label}`}</span>
            )}
          </Tooltip>
        </Menu.Item>
      );
    }
  };

  const getThematicAreaItem = (item: any, index: number, itr: number) => {
    //tmp key assignment, before we actually have unique keys
    const rootTopics = [503827296, 1471028664, 414038628, 975485693, 646330495, 53461843, 885633335, 1134626659, 310640129, 343527823, 181604978];
    let tagId = item.id;
    itr++;

    if (item.children && item.children.length > 0 && itr < 2) {
      return (
        <SubMenu
          className={`-dc-sidebar-submenu -dc-sidebar-submenu-${tagId}`}
          key={tagId}
          icon={typeof item.icon !== "undefined" ? <Icon component={() => <img style={{ width: "32px" }} src={`${item.icon}`} />} /> : ""}
          title={
            <Tooltip mouseEnterDelay={0.6} placement={breakpoint.xs ? "top" : "right"} title={item.label}>
              {item.label}
            </Tooltip>
          }
        >
          {item.children.map((subItem: any, subIndex: number) => {
            return getThematicAreaItem(subItem, subIndex, itr);
          })}
        </SubMenu>
      );
    }

    //icon={<Icon component={() => (<img src={`${item.icon}`} />)} />}
    return (
      <>
        <Menu.Item className={`-title-content -dc-sidebar-menu-item -dc-sidebar-menu-item-${tagId}`} key={tagId} id={tagId}>
          <Tooltip mouseEnterDelay={0.6} placement={breakpoint.xs ? "top" : "right"} title={item.label ? item.label : item.indicator_name}>
            <span dangerouslySetInnerHTML={{ __html: item.label ? item.label : item.indicator_name }}></span>
          </Tooltip>
        </Menu.Item>
      </>
    );
  };

  const goToIndicator = (value: { value: string }) => {
    let url = routeDataCommonsConstants.THEMATIC_AREAS;

    if (countryPage) {
      url = routeDataCommonsConstants.COUNTRY;
    }

    //check if this is an explore topic item
    if (value.key.includes("topic")) {
      if (value.keyPath.length == 3) {
        if (placeDcid && countryPage) {
          history.push(`${url}/${value.keyPath[2]}?p=${placeDcid}#${value.keyPath[1]}`);
        } else {
          history.push(`${url}/${value.keyPath[2]}#${value.keyPath[1]}`);
        }
      } else {
        if (placeDcid && countryPage) {
          history.push(`${url}/${value.keyPath[1]}?p=${placeDcid}`);
        } else {
          history.push(`${url}/${value.keyPath[1]}`);
        }
      }
      return;
    }

    if (typeof value.key != "undefined") {
      //if this is an indicator or subtopic
      if (typeof value.keyPath != "undefined") {
        const path = value.keyPath;
        if (path.length == 2) {
          if (placeDcid && countryPage) {
            history.push(`${url}/${path[1]}?p=${placeDcid}#${path[0]}`);
          } else {
            history.push(`${url}/${path[1]}#${path[0]}`);
          }
        } else {
          if (placeDcid && countryPage) {
            history.push(`${url}/${path[2]}?p=${placeDcid}#${path[1]}+${path[0]}`);
          } else {
            history.push(`${url}/${path[2]}#${path[1]}+${path[0]}`);
          }
        }
      }
    }
  };

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    setOpenTopic(keys);
  };
  const onOpenSDGChange: MenuProps["onOpenChange"] = (keys) => {
    setOpenSDGs(keys);
  };

  useEffect(() => {
    (async () => {
      const hierarchy = await fetchPlaceSidebarMenuHierarchy({
        placeDcid,
        allTopicDcids,
        sidebarMenuHierarchy,
        isGoal: !showThematicAreas,
      });
      setPlaceSidebarMenuHierarchy(hierarchy);

      if (!showThematicAreas) {
        setDefaultPanel(2);
      }
    })();
  }, [placeDcid, allTopicDcids, sidebarMenuHierarchy]);

  useEffect(() => {
    let params = Object.fromEntries(new URLSearchParams(window.location.search));
    // sdgs
    if (params.v?.includes(ROOT_TOPIC)) {
      const [base, levels] = params.v.split(/_(.+)/); // Split into base and the rest
      const openedKeys = levels?.split(".").map((_, i, arr) => `${base}_${arr.slice(0, i + 1).join(".")}`);
      setOpenSDGs(openedKeys);
    }
    //areas
    if (thematicArea) {
      setSelectedIndicators([thematicArea.id + "-topic"]);
    }
  }, [window.location.pathname, window.location.search, window.location.hash]);

  useEffect(() => {
    if (hash) {
      const path = hash.replace(/#/g, "").split("+");
      setOpenTopic([...openTopic, path[0].toString()]);
      if (path.length == 1) {
        setSelectedIndicators([path[0].toString()]);
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    setOpenTopic([dcid]);
  }, [dcid]);

  const breakpoint = useBreakpoint();
  const headerHeight = breakpoint.xs ? HEADER_HEIGHT_XS : HEADER_HEIGHT_XL;

  return (
    <RespSidebar headerHeight={headerHeight}>
      <SidebarContent dcid={dcid}>
        {/* {!showThematicAreas && <MenuTitle>Goals</MenuTitle>} */}
        {showThematicAreas && (
          <Collapse
            onChange={(key) => setDefaultPanel(key)}
            expandIcon={() => <i className="ant-menu-submenu-arrow"></i>}
            ghost
            expandIconPosition="end"
            defaultActiveKey={["1"]}
            activeKey={defaultPanel}
          >
            <Panel style={{ padding: 0 }} header={ThematicAreaHeader()} key="1">
              <StyledMenu
                onOpenChange={onOpenChange}
                defaultOpenKeys={openTopic}
                openKeys={openTopic}
                selectedKeys={selectedIndicators}
                mode="inline"
                style={{ borderRight: 0 }}
                onClick={(item) => {
                  goToIndicator(item);
                }}
              >
                {placeSidebarMenuHierarchy.length === 0 || topics.length === 0 ? (
                  <Spinner />
                ) : (
                  <>{topics.filter((v) => v.type != "placeholder").map((vg, index) => getThematicAreaItem(vg, index, 0))}</>
                )}
              </StyledMenu>
            </Panel>

            {!onlySDGlink && (
              <Panel style={{ padding: 0 }} header={SDGheader()} key="2">
                <StyledMenu
                  onOpenChange={onOpenSDGChange}
                  defaultOpenKeys={openSDGs}
                  openKeys={openSDGs}
                  selectedKeys={[variableDcid, `summary-${variableDcid}`]}
                  mode="inline"
                  style={{ borderRight: 0 }}
                  onClick={(item) => {
                    setVariableDcid(item.key.replace("summary-", ""));
                  }}
                >
                  {placeSidebarMenuHierarchy.length === 0 || topics.length === 0 ? <Spinner /> : <>{placeSidebarMenuHierarchy.map((vg) => getMenuItem(vg))}</>}
                </StyledMenu>
              </Panel>
            )}

            {onlySDGlink && <Panel showArrow={false} style={{ padding: 0 }} header={SDGheader()} key="2"></Panel>}
          </Collapse>
        )}

        {!showThematicAreas && (
          <StyledMenu
            onOpenChange={onOpenSDGChange}
            defaultOpenKeys={openSDGs}
            openKeys={openSDGs}
            selectedKeys={[variableDcid, `summary-${variableDcid}`]}
            mode="inline"
            style={{ borderRight: 0 }}
            className="goals-menu"
            onClick={(item) => {
              setVariableDcid(item.key.replace("summary-", ""));
            }}
          >
            {placeSidebarMenuHierarchy.length === 0 ? <Spinner /> : <>{placeSidebarMenuHierarchy.map((vg) => getMenuItem(vg))}</>}
          </StyledMenu>
        )}
      </SidebarContent>
    </RespSidebar>
  );
};

const Spinner = () => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            paddingLeft: "1.5rem",
            fontSize: "1.5rem",
          }}
          spin
        />
      }
    />
  );
};

export default AppSidebar;